export const environment = {
  production: false,
  apiBaseUrl: 'https://hub.dev.vibeiq.com/api-dev',
  showcaseManagerAppHost: 'https://staging.showcase-manager.contrailplatform.com/',
  showcaseViewerAppHost: 'https://staging.showcase.contrailplatform.com/',
  planAppHost: 'https://staging.plans.contrailplatform.com/',
  boardsAppHost: 'https://staging.boards.contrailplatform.com/',
  adminAppHost: 'https://staging.admin.contrailplatform.com/',
  hubAppHost: 'https://staging.hub.contrailplatform.com/',
  loginAppHost: 'https://login.contrailplatform.com/',
  showroomAppHost: 'https://staging.showroom.contrailplatform.com/',
  userPoolRegion: 'us-east-1',
  userPoolId: 'us-east-1_e2cA0nJWu',
  userPoolWebClientId: 'nnio2uhtq7qfk1tjmi7tgg63p',
  name: 'FEATURE_BRANCH',
  googleAnalyticsKey: 'UA-179147959-1',
  intercomAppId: 'e2uyp9md',
  domain: '.contrailplatform.com',
  loginUrl: 'https://login.contrailplatform.com/',
  websocketService: 'wss://qde1jn70k0.execute-api.us-east-1.amazonaws.com/dev/',
  appName: 'Hub',
  ngrxHistory: 2,
};
